import { env } from '../../../configs';
import { requestApi, apiPaths, methods, handleResponse, requestApiFormData, requestBffApi } from '../../config';

const shouldUseBffApi = env.REACT_APP_USE_BFF_API === 'true';

export const jobService = {
    cancelJob,
    completeJob,
    getJob,
    getJobValidationStatuses,
    updateJob,
    approveJob,
    assignNewPartner,
    assignNewPartnerStandard,
    getJobInvoices,
    getJobScopeFileUrl,
    startBiddingJob,
    sendJobToClient,
    getJobGeneratedOffers,
    postJobGeneratedOffers,
    postSetCalled,
    postNewCompetitorOffer,
    putCompetitorOffer,
    deleteCompetitorOffer
};

function cancelJob(jobId) {
    return requestApi(apiPaths.jobs.index + '/' + jobId + '/cancel', {
        method: methods.post
    }).then(handleResponse);
}

function startBiddingJob(jobId) {
    return requestApi(apiPaths.jobs.index + '/' + jobId + '/start-processing', {
        method: methods.post
    }).then(handleResponse);
}

function sendJobToClient(jobId) {
    return requestApi(apiPaths.jobs.index + '/' + jobId + '/send-to-client', {
        method: methods.post
    }).then(handleResponse);
}

function completeJob(jobId, requestData) {
    return requestApi(apiPaths.jobs.index + '/' + jobId + '/complete', {
        method: methods.post,
        data: requestData
    }).then(handleResponse);
}

function getJob(jobId) {
    if (shouldUseBffApi) {
        return requestBffApi(apiPaths.jobs.index + '/' + jobId).then(handleResponse);
    }
    return requestApi(apiPaths.jobs.index + '/' + jobId).then(handleResponse);
}

function getJobValidationStatuses() {
    return requestApi(apiPaths.jobs.index + '/validation-statuses').then(handleResponse);
}

function updateJob(jobId, jobData) {
    return requestApiFormData(apiPaths.jobs.index + '/' + jobId, {
        method: methods.put,
        data: jobData
    }).then(handleResponse);
}

function assignNewPartner(jobId, partnerId) {
    return requestApiFormData(`${apiPaths.jobs.index}/${jobId}/assign-new-partner/${partnerId}`, {
        method: methods.put
    }).then(handleResponse);
}

function assignNewPartnerStandard(jobId, partnerId) {
    return requestApiFormData(`${apiPaths.jobs.index}/${jobId}/assign-new-partner/${partnerId}/standard`, {
        method: methods.put
    }).then(handleResponse);
}

function approveJob(jobId, payload) {
    return requestApi(apiPaths.jobs.index + '/' + jobId + '/approve', {
        method: methods.post,
        data: payload
    }).then(handleResponse);
}

function getJobInvoices(jobId) {
    return requestApi(apiPaths.jobs.index + '/' + jobId + '/invoices').then(handleResponse);
}

function getJobScopeFileUrl(jobId, partnerId) {
    return requestApi(apiPaths.jobs.index + '/' + jobId + '/bids/' + partnerId + '/job-scope-file');
}

function getJobGeneratedOffers(jobId, data) {
    return requestBffApi(apiPaths.jobs.v2 + '/' + jobId + '/offers/generate', { method: 'POST', data });
}

function postJobGeneratedOffers(jobId, data) {
    return requestBffApi(apiPaths.jobs.v2 + '/' + jobId + '/offers/generated', { method: 'POST', data });
}

function postSetCalled(jobId, data) {
    return requestApi(apiPaths.jobs.index + '/' + jobId + '/admins/call', { method: 'POST', data });
}

function postNewCompetitorOffer(jobId, data) {
    return requestApi(apiPaths.jobs.index + '/' + jobId + '/offers', { method: 'POST', data });
}

function putCompetitorOffer(jobId, orderBidId, data) {
    return requestApi(apiPaths.jobs.index + '/' + jobId + '/offers/' + orderBidId, { method: 'PUT', data });
}

function deleteCompetitorOffer(jobId, orderBidId) {
    return requestApi(apiPaths.jobs.index + '/' + jobId + '/offers/' + orderBidId, { method: 'DELETE' });
}
